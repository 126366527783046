.home-wip-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}
.home-wip-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-wip-bg {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.7;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.home-wip-container1 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-wip-video {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100vw;
  bottom: 0px;
  height: 100vh;
  position: fixed;
  object-fit: cover;
}
.home-wip-image {
  width: 5%;
  height: auto;
  z-index: 100;
  object-fit: cover;
}
.home-wip-text-image {
  width: 50%;
  height: auto;
  z-index: 100;
  object-fit: cover;
}
video#videoElement::-webkit-media-controls,
video#videoElement::-webkit-media-controls-start-playback-button,
video#videoElement::-webkit-media-controls-play-button,
video#videoElement::-webkit-media-controls-panel,
video#videoElement::-webkit-media-controls-container,
video#videoElement::-webkit-media-controls-overlay-play-button,
video#videoElement::-webkit-media-controls-enclosure {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}
